import { FieldProps } from 'formik';
import React, { useRef, useState, MouseEvent, DragEvent, useEffect } from 'react';
import clsx from 'clsx';
import { saveFileListToFormikField } from './utils';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { byteSizeToHumanReadable } from '@utils';

interface IProps extends FieldProps {
    autoSubmitOnceValid: boolean;
    defaultValue?: string;
}

export default function DragAndDropArea(props: IProps) {
    const { field, form, autoSubmitOnceValid, defaultValue } = props;
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const selectedFile = (field.value as FileList)?.[0] ?? null;

    useEffect(() => {
        if (autoSubmitOnceValid && form.isValid && selectedFile) {
            form.submitForm();
        }
    }, [form.isValid, selectedFile]);

    const handleFileSelection = (files: FileList | null) => {
        saveFileListToFormikField(files, field, form, false);
    };

    const handleClearFile = (event: MouseEvent) => {
        event.stopPropagation();
        form.setFieldValue(field.name, '');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        handleFileSelection(event.dataTransfer.files);
    };

    return (
        <div
            className={clsx(
                'h-36 flex-grow flex flex-col border-2 border-dotted justify-center items-center rounded-md group',
                isDragging ? 'border-emerald-500' : 'border-gray-300',
                { 'cursor-pointer': !selectedFile }
            )}
            onDrop={handleDrop}
            onDragOver={(event) => {
                event.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            onClick={() => !selectedFile && fileInputRef.current?.click()}
        >
            <input
                hidden={true}
                multiple={false}
                onChange={(event) => handleFileSelection(event.target.files)}
                ref={fileInputRef}
                type='file'
            />
            {selectedFile ? (
                <div className='flex flex-row space-x-2 items-center'>
                    <h2 className='text-lg text-gray-500 m-0'>
                        {selectedFile?.name} - {byteSizeToHumanReadable(selectedFile?.size)}
                    </h2>
                    <button
                        onClick={handleClearFile}
                        title='Clear selected file'
                        className='p-1 text-gray-500 rounded-md bg-gray-50 hover:bg-gray-100 hover:text-red-600 m-0'
                    >
                        <XMarkIcon className='h-5 w-5' />
                    </button>
                </div>
            ) : (
                <div className='text-center'>
                    <ArrowDownTrayIcon className='h-10 w-10 text-gray-500 group-hover:text-cyan-800 mx-auto' />
                    <p className='text-lg text-gray-500 group-hover:text-cyan-800 select-none my-auto pr-2'>
                        <b>Choose a file</b> or drag it here
                    </p>
                </div>
            )}
            <div>
                {defaultValue && !selectedFile && (
                    <span className='text-gray-600'>
                        [Using default file: {defaultValue}. To change, select a new file.]
                    </span>
                )}
            </div>
        </div>
    );
}
